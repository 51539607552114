import classNames from "classnames";
import styles from "./Logo.module.css";

export interface LogoProps {
  size?: "medium" | "large";
}

export const Logo = ({ size = "large" }: LogoProps) => (
  <div className={classNames(styles.logo, styles[size])}>
    <div className={styles.icon} />
    <div className={styles.text}>PassParcel</div>
  </div>
);
