import classNames from "classnames";
import { Logo } from "../Logo/Logo";
import { PrimaryBox } from "../PrimaryBox/PrimaryBox";
import styles from "./UserPage.module.css";

export interface UserPageProps {
  onSignOut: () => void;
}

export const UserPage = ({ onSignOut }: UserPageProps) => {
  const emailAddress = localStorage.getItem("signed-in-email-address");

  return (
    <PrimaryBox wide>
      <div className={styles.header}>
        <Logo size="medium" />
        <button className={styles.signOut} onClick={() => onSignOut()}>
          Sign out
        </button>
      </div>
      <div className={styles.account}>
        <h2>My account</h2>
        <div className={styles.accountEmailAddress}>{emailAddress}</div>
      </div>
      <div className={styles.arrivingParcel}>
        <div className={classNames(styles.parcelIcon, styles.small)} />
        <div className={styles.arrivingParcelText}>
          <b>1</b> parcel expected <b>today</b>
        </div>
      </div>
      <div className={styles.parcelListTitle}>Last 30 days</div>
      <ul className={styles.parcelList}>
        <li>
          <div className={classNames(styles.parcelIcon, styles.small)} />
          <div className={styles.parcelListItemDate}>08/10/2022</div>
          <div className={styles.parcelListItemDelivery}>
            <b>2</b> parcels delivered
          </div>
        </li>
        <li>
          <div className={classNames(styles.parcelIcon, styles.small)} />
          <div className={styles.parcelListItemDate}>08/02/2022</div>
          <div className={styles.parcelListItemDelivery}>
            <b>1</b> parcel delivered
          </div>
        </li>
        <li>
          <div className={classNames(styles.parcelIcon, styles.small, styles.failed)} />
          <div className={styles.parcelListItemDate}>08/01/2022</div>
          <div className={styles.parcelListItemDelivery}>
            <b>1</b> parcel delivery attempted
          </div>
        </li>
        <li>
          <div className={classNames(styles.parcelIcon, styles.small)} />
          <div className={styles.parcelListItemDate}>07/27/2022</div>
          <div className={styles.parcelListItemDelivery}>
            <b>3</b> parcels delivered
          </div>
        </li>
      </ul>
    </PrimaryBox>
  );
};
