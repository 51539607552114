import { useCallback, useEffect, useState } from "react";
import { SignIn } from "../SignIn/SignIn";
import { SignUp } from "../SignUp/SignUp";
import { SignUpSuccess } from "../SignUpSuccess/SignUpSuccess";
import { UserPage } from "../UserPage/UserPage";
import styles from "./App.module.css";

type AppLocation = "user" | "sign-in" | "sign-up" | "sign-up-success";

export const App = () => {
  const [{ isLoading, isAuthorized }, setResult] = useState<{ isLoading: boolean; isAuthorized: boolean }>({
    isLoading: true,
    isAuthorized: false,
  });
  const [location, setLocation] = useState<AppLocation>("sign-up");

  const checkUserAuthorization = useCallback(() => {
    const signedInEmailAddress = localStorage.getItem("signed-in-email-address");
    setResult({ isLoading: false, isAuthorized: !!signedInEmailAddress });
    setLocation((currentLocation) => {
      if (signedInEmailAddress) {
        // Temporary for demo video. Remove for production.
        if (currentLocation === "sign-up") return "sign-up-success";
        //

        return "user";
      } else {
        // Temporary for demo video. Remove for production.
        if (currentLocation === "sign-up-success") return "sign-in";
        //

        if (currentLocation === "user") return "sign-in";
        return "sign-up";
      }
    });
  }, []);

  useEffect(() => checkUserAuthorization(), [checkUserAuthorization]);

  const signIn = (emailAddress: string) => {
    localStorage.setItem("signed-in-email-address", emailAddress);
    checkUserAuthorization();
  };

  if (isLoading) return <>Loading…</>;

  return (
    <div className={styles.app}>
      <div className={styles.passageCallout}>
        <h3>Add passkey support to your website</h3>
        <p>
          We&apos;ve partnered with Passage to make this demo possible. Use the <a href="#">Passage API</a> to give your
          customers tomorrow&apos;s experience today—no time machine required.
        </p>
      </div>
      {(() => {
        switch (location) {
          case "sign-in":
            return (
              <SignIn
                onSubmit={(emailAddress) => {
                  const registeredEmailAddresses = JSON.parse(
                    localStorage.getItem("registered-email-addresses") ?? "[]"
                  );
                  if (registeredEmailAddresses.includes(emailAddress)) {
                    document.dispatchEvent(
                      new CustomEvent("passage-login-before-auth", { bubbles: false, cancelable: false })
                    );

                    setTimeout(() => {
                      signIn(emailAddress);
                      document.dispatchEvent(
                        new CustomEvent("passage-login-on-success", { bubbles: false, cancelable: false })
                      );
                    }, 1500);
                  }
                }}
              />
            );
          case "sign-up":
            return (
              <SignUp
                onSubmit={(emailAddress) => {
                  const registeredEmailAddresses = JSON.parse(
                    localStorage.getItem("registered-email-addresses") ?? "[]"
                  );
                  localStorage.setItem(
                    "registered-email-addresses",
                    JSON.stringify([...new Set([...registeredEmailAddresses, emailAddress]).values()])
                  );

                  document.dispatchEvent(
                    new CustomEvent("passage-register-before-auth", { bubbles: false, cancelable: false })
                  );

                  setTimeout(() => {
                    signIn(emailAddress);
                    document.dispatchEvent(
                      new CustomEvent("passage-register-on-success", { bubbles: false, cancelable: false })
                    );
                  }, 1500);
                }}
              />
            );
          // Temporary for demo video. Remove for production.
          case "sign-up-success":
            return (
              <SignUpSuccess
                onDone={() => {
                  localStorage.removeItem("signed-in-email-address");
                  checkUserAuthorization();
                }}
              />
            );
          //
          case "user":
            return (
              <UserPage
                onSignOut={() => {
                  localStorage.removeItem("signed-in-email-address");
                  checkUserAuthorization();
                }}
              />
            );
        }
      })()}
    </div>
  );
};
