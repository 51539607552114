import { AuthenticationBox } from "../AuthenticationBox/AuthenticationBox";
import { AuthenticationForm, AuthenticationFormProps } from "../AuthenticationForm/AuthenticationForm";

export interface SignUpProps {
  onSubmit: AuthenticationFormProps["onSubmit"];
}

export const SignUp = ({ onSubmit }: SignUpProps) => (
  <AuthenticationBox title="Sign up">
    <AuthenticationForm onSubmit={onSubmit} />
  </AuthenticationBox>
);
