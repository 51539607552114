import { AuthenticationBox } from "../AuthenticationBox/AuthenticationBox";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import styles from "./SignUpSuccess.module.css";

export interface SignUpSuccessProps {
  onDone: () => void;
}

export const SignUpSuccess = ({ onDone }: SignUpSuccessProps) => (
  <AuthenticationBox title="Success!">
    <div className={styles.accountCreated}>Your account has been created.</div>
    <div className={styles.primaryButton}>
      <PrimaryButton onClick={() => onDone()}>Ok</PrimaryButton>
    </div>
  </AuthenticationBox>
);
