import classNames from "classnames";
import styles from "./PrimaryBox.module.css";

export interface PrimaryBoxProps {
  children?: React.ReactNode;
  wide?: boolean;
}

export const PrimaryBox = ({ children, wide }: PrimaryBoxProps) => {
  return <div className={classNames(styles.primaryBox, { [styles.wide]: wide })}>{children}</div>;
};
