import styles from "./PrimaryButton.module.css";

export interface PrimaryButtonProps {
  children?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const PrimaryButton = ({ children, disabled, onClick }: PrimaryButtonProps) => (
  <button className={styles.primaryButton} disabled={disabled} onClick={() => onClick?.()}>
    {children}
  </button>
);
