import { AuthenticationBox } from "../AuthenticationBox/AuthenticationBox";
import { AuthenticationForm, AuthenticationFormProps } from "../AuthenticationForm/AuthenticationForm";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import styles from "./SignIn.module.css";

export interface SignInProps {
  onSubmit: AuthenticationFormProps["onSubmit"];
}

export const SignIn = ({ onSubmit }: SignInProps) => {
  const registeredEmailAddresses = JSON.parse(localStorage.getItem("registered-email-addresses") ?? "[]");
  const firstRegisteredEmailAddress: string | undefined = registeredEmailAddresses[0];

  return (
    <AuthenticationBox title={firstRegisteredEmailAddress ? "Welcome back!" : "Sign in"}>
      {firstRegisteredEmailAddress ? (
        <>
          <div className={styles.emailAddress}>
            Sign in as <b>{firstRegisteredEmailAddress}</b>?
          </div>
          <div className={styles.primaryButton}>
            <PrimaryButton onClick={() => onSubmit(firstRegisteredEmailAddress)}>Continue</PrimaryButton>
          </div>
        </>
      ) : (
        <AuthenticationForm onSubmit={onSubmit} />
      )}
      <div className={styles.secondaryButton}>
        <PrimaryButton disabled>Switch account</PrimaryButton>
      </div>
    </AuthenticationBox>
  );
};
