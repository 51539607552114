import { Logo } from "../Logo/Logo";
import { PrimaryBox } from "../PrimaryBox/PrimaryBox";
import styles from "./AuthenticationBox.module.css";

export interface AuthenticationBoxProps {
  children?: React.ReactNode;
  title: string;
}

export const AuthenticationBox = ({ children, title }: AuthenticationBoxProps) => (
  <PrimaryBox>
    <div className={styles.authenticationBox}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <h1 className={styles.title}>{title}</h1>
      {children}
    </div>
  </PrimaryBox>
);
