import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import styles from "./AuthenticationForm.module.css";

export interface AuthenticationFormProps {
  onSubmit: (emailAddress: string) => void;
}

export const AuthenticationForm = ({ onSubmit }: AuthenticationFormProps) => (
  <form
    onSubmit={(event) => {
      event.preventDefault();
      if (!(event.target instanceof HTMLFormElement)) return;
      const emailAddress = new FormData(event.target).get("email")?.toString();
      if (emailAddress) onSubmit(emailAddress);
    }}
  >
    <input className={styles.input} name="email" placeholder="Email address" />
    <div className={styles.primaryButton}>
      <PrimaryButton>Continue</PrimaryButton>
    </div>
  </form>
);
